<template>
  <div>
    <template>
      <v-container>
        <!-- One Logo / Slogan -->
        <div style="height: 15vh">
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 5vh;
            "
          >
            <img src="@/assets/logos/dime_logo_gold.png" style="height: 7em" />
          </div>
        </div>
        <div style="height: 70vh">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: flex-end;
            "
            class="mb-3"
          >
            <!-- <img
              src="@/assets/logos/dime_thinkhigher.png"
              style="height: 2.5em"
            /> -->
          </div>
          <div style="height: 100%; overflow-y: auto">
            <p
              class="
                text-uppercase text-center
                font-weight-bold
                white--text
                mb-3
              "
            >
              set up your profile
            </p>
            <form style="width: 100%" @submit.prevent>
              <v-row no-gutters>
                <v-col cols="6" class="mb-2">
                  <div>
                    <input
                      ref="FIRST"
                      type="text"
                      :style="`
                  background-color: transparent;
                  color: white;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;
                `"
                      placeholder="FIRST"
                      v-model.trim="payload.user.name.first"
                      class="form-control py-2"
                      maxlength="50"
                      @blur="onBlurFirstName"
                    />
                  </div>
                </v-col>
                <v-col cols="6">
                  <div>
                    <input
                      ref="LAST"
                      type="text"
                      :style="`
                  background-color: transparent;
                  color: white;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;
                `"
                      placeholder="LAST"
                      v-model.trim="payload.user.name.last"
                      class="form-control py-2"
                      maxlength="50"
                      @blur="onBlurLastName"
                    />
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="mb-3">
                    <input
                      ref="COUNTRY"
                      type="text"
                      :style="`
                  background-color: transparent;
                  color: white;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;
                `"
                      placeholder="COUNTRY"
                      v-model.trim="payload.user.location.countryName"
                      class="form-control py-2"
                      maxlength="100"
                      readonly="readonly"
                      @blur="onBlurCountry"
                      @click="onClickCountry"
                    />
                  </div>
                </v-col>
                <v-col cols="6">
                  <input
                    ref="STATE"
                    type="text"
                    :style="`
                  background-color: transparent;
                  color: white;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;
                `"
                    placeholder="STATE"
                    v-model.trim="payload.user.location.stateName"
                    class="form-control py-2"
                    maxlength="100"
                    @blur="onBlurState"
                    @click="onClickCountry"
                    onfocus="this.blur();"
                  />
                </v-col>
                <v-col cols="12" class="text-center my-3">
                  <span
                    class="text-uppercase white--text font-italic"
                    style="font-size: 0.9em"
                  >
                    due to the recent pact act, we are required to confirm you
                    are older than 21
                  </span>
                </v-col>
                <v-col cols="12" class="mb-3">
                  <div>
                    <input
                      ref="AGE"
                      type="text"
                      :style="`
                  background-color: transparent;
                  color: white;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;
                `"
                      placeholder="VERIFY AGE"
                      v-model.trim="payload.user.showAge"
                      class="form-control py-2"
                      maxlength="3"
                      readonly="readonly"
                      @blur="onBlurAge"
                      @click="onClickVerifyAge"
                      oninput="value=value.replace(/[^\d]/g,'')"
                    />
                  </div>
                </v-col>

                <!-- App -->
                <v-col cols="12" class="mb-3">
                  <input
                    ref="PASSWORD"
                    type="password"
                    :style="`
                  background-color: transparent;
                  color: white;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;
                `"
                    placeholder="PASSWORD"
                    v-model.trim="payload.user.password.initial"
                    class="form-control py-2"
                    maxlength="20"
                    @blur="onBlurPassword"
                  />
                </v-col>
                <v-col cols="12" class="mb-3">
                  <input
                    ref="CONFIRMPASSWORD"
                    type="password"
                    :style="`
                  background-color: transparent;
                  color: white;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;
                `"
                    placeholder="CONFIRM PASSWORD"
                    v-model.trim="payload.user.password.confirm"
                    class="form-control py-2"
                    maxlength="20"
                    @blur="onBlurConfirmPassword"
                  />
                </v-col>
                <v-col cols="12" class="text-center">
                  <p
                    class="white--text font-weight-bold text-uppercase"
                    style="font-size: 0.8em"
                  >
                    by registering, you agree to our<br />
                    <span class="blue--text" @click="traverse('terms')"
                      >terms, data policy &amp; cookie policy</span
                    >
                  </p>
                </v-col>
              </v-row>
            </form>
            <!-- Complete Profile -->
            <div class="" style="margin-top: 3vh">
              <button
                class="text-white py-1 button-confirm"
                :style="`
                    background-color: ${getAccentColor};
                    border: none;
                    width: 100%;
                    border-radius: 30px;
                    font-weight: 500;
                    `"
                @click="traverse('connect_device')"
                :disabled="isDisabledConfirm"
              >
                <span style="text-transform: uppercase; font-size: 1.5em">
                  complete profile
                </span>
              </button>
            </div>
          </div>
        </div>
      </v-container>
      <CountryState
        :show="showCountryState"
        @child-close="closeCountryStateDialog"
        @child-finish="finishCountryState"
      />
      <VerifyAge
        :show="showVerifyAge"
        @child-cancel="cancelVerifyDialog"
        @child-confirm="confirmVerifyDialog"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Toast } from "vant";
import CountryState from "@/components/dialog/CountryStateDialog.vue";
import VerifyAge from "@/components/dialog/VerifyAgeDialog.vue";

export default {
  name: "complete-user-profile",
  data() {
    return {
      response: {},
      payload: {
        user: {
          name: {
            first: "",
            last: "",
          },
          age: "",
          showAge: "",
          dateOfBirth: "",
          location: {
            country: "",
            countryName: "",
            state: "",
            stateName: "",
          },
          password: {
            initial: "",
            confirm: "",
          },
        },
      },
      startChecking: true, //是否开始验证
      showCountryState: false,
      showVerifyAge: false,
    };
  },
  components: {
    CountryState: CountryState,
    VerifyAge: VerifyAge,
  },
  created() {},
  methods: {
    ...mapActions(["_setLoginState", "_setLoginInfo"]),
    //#region onBlurFirstName
    onBlurFirstName() {
      let value = this.payload.user.name.first;
      if (this.startChecking) {
        if (value.length == 0) {
          this.$refs.FIRST.style.borderColor = "#FF4141";
        } else {
          this.$refs.FIRST.style.borderColor = this.getAccentColor;
        }
      }
    },
    //#endregion
    //#region onBlurLastName
    onBlurLastName() {
      let value = this.payload.user.name.last;
      if (this.startChecking) {
        if (value.length == 0) {
          this.$refs.LAST.style.borderColor = "#FF4141";
        } else {
          this.$refs.LAST.style.borderColor = this.getAccentColor;
        }
      }
    },
    //#endregion
    //#region onBlurCountry
    onBlurCountry() {
      let value = this.payload.user.location.country;
      if (this.startChecking) {
        if (value.length == 0) {
          this.$refs.COUNTRY.style.borderColor = "#FF4141";
        } else {
          this.$refs.COUNTRY.style.borderColor = this.getAccentColor;
        }
      }
    },
    //#endregion
    //#region onBlurState
    onBlurState() {
      let value = this.payload.user.location.state;
      if (this.startChecking) {
        if (value.length == 0) {
          this.$refs.STATE.style.borderColor = "#FF4141";
        } else {
          this.$refs.STATE.style.borderColor = this.getAccentColor;
        }
      }
    },
    //#endregion
    //#region onBlurAge
    onBlurAge() {
      let value = this.payload.user.age;
      if (this.startChecking) {
        if (value.length == 0 || value < 21 || value > 120) {
          this.$refs.AGE.style.borderColor = "#FF4141";
        } else {
          this.$refs.AGE.style.borderColor = this.getAccentColor;
        }
      }
    },
    //#endregion
    //#region onBlurPassword
    onBlurPassword() {
      let value = this.payload.user.password.initial;
      if (this.startChecking) {
        if (value.length == 0 || value.length < 6) {
          this.$refs.PASSWORD.style.borderColor = "#FF4141";
        } else {
          this.$refs.PASSWORD.style.borderColor = this.getAccentColor;
        }
      }
    },
    //#endregion
    //#region onBlurConfirmPassword
    onBlurConfirmPassword() {
      let value = this.payload.user.password.confirm;
      if (this.startChecking) {
        if (value.length == 0 || value.length < 6) {
          this.$refs.CONFIRMPASSWORD.style.borderColor = "#FF4141";
        } else if (value != this.payload.user.password.initial) {
          this.$refs.CONFIRMPASSWORD.style.borderColor = "#FF4141";
          // this.$refs.CONFIRMPASSWORD.focus();
        } else {
          this.$refs.CONFIRMPASSWORD.style.borderColor = this.getAccentColor;
        }
      }
    },
    //#endregion

    //#region onClickCountry
    onClickCountry() {
      this.showCountryState = true;
    },
    //#endregion
    //#region onClickVerifyAge
    onClickVerifyAge() {
      this.showVerifyAge = true;
    },
    //#endregion
    //#region closeCountryStateDialog
    closeCountryStateDialog(data) {
      this.showCountryState = data;
    },
    //#endregion
    //#region finishCountryState
    finishCountryState(country, state) {
      console.log(country, state);
      this.payload.user.location.country = country.id;
      this.payload.user.location.state = state.id;
      this.payload.user.location.countryName = country.name;
      this.payload.user.location.stateName = state.name;
      this.$refs.COUNTRY.focus();
      this.$refs.COUNTRY.blur();
      this.$refs.STATE.focus();
      this.$refs.STATE.blur();
    },
    //#endregion
    //#region cancelVerifyDialog
    cancelVerifyDialog(data) {
      this.showVerifyAge = data;
    },
    //#endregion
    //#region confirmVerifyDialog
    confirmVerifyDialog(data) {
      let value_year = new Date(data).getFullYear();
      let value_month = new Date(data).getMonth() + 1;
      let value_day = new Date(data).getDate();
      let show_month =
        value_month < 10
          ? "0" + value_month.toString()
          : value_month.toString();
      let resp =
        value_year.toString() +
        "-" +
        show_month.toString() +
        "-" +
        value_day.toString();
      this.payload.user.dateOfBirth = resp;
      this.payload.user.showAge =
        this.formatter(value_month) + " " + value_day + "," + value_year;

      this.payload.user.age = this.getAge(
        (value_year + "-" + value_month + "-" + value_day).split("-")
      );
      this.$refs.AGE.focus();
      this.$refs.AGE.blur();
    },
    //#endregion

    traverse(action) {
      switch (action) {
        case "connect_device":
          //#region 校验
          this.startChecking = true;
          if (!this.firstNameSet) {
            this.$showTips({ msg: "Input a First Name" });
            // Toast.fail("Input a First Name");
            this.$refs.FIRST.style.borderColor = "#FF4141";
            this.$refs.FIRST.focus();
            return;
          }
          if (!this.lastNameSet) {
            this.$showTips({ msg: "Input a Last Name" });
            // Toast.fail("Input a Last Name");
            this.$refs.LAST.style.borderColor = "#FF4141";
            this.$refs.LAST.focus();
            return;
          }

          if (!this.countrySet) {
            this.$showTips({ msg: "Input your Country" });
            // Toast.fail("Input your Country");
            this.$refs.COUNTRY.style.borderColor = "#FF4141";
            this.$refs.COUNTRY.focus();
            return;
          }
          if (!this.stateSet) {
            this.$showTips({ msg: "Input your State" });
            // Toast.fail("Input your State");
            this.$refs.STATE.style.borderColor = "#FF4141";
            this.$refs.STATE.focus();
            return;
          }
          if (this.payload.user.age.length == 0) {
            this.$showTips({ msg: "Please enter a DOB" });

            // Toast.fail("Please enter a DOB");
            this.$refs.AGE.style.borderColor = "#FF4141";
            this.$refs.AGE.focus();
            return;
          }
          if (this.payload.user.age < 21 || this.payload.user.age > 120) {
            this.$showTips({ msg: "Please enter dob Between 21 and 120." });
            // Toast.fail("Please enter dob Between 21 and 120.");
            this.$refs.AGE.style.borderColor = "#FF4141";
            this.$refs.AGE.focus();
            return;
          }
          if (this.payload.user.password.initial.length == 0) {
            this.$showTips({ msg: "Input a password." });
            // Toast.fail("Input a password.");
            this.$refs.PASSWORD.style.borderColor = "#FF4141";
            this.$refs.PASSWORD.focus();
            return;
          }
          if (
            this.payload.user.password.initial.length > 0 &&
            this.payload.user.password.initial.length < 6
          ) {
            this.$showTips({ msg: "Input a 6 digits password." });
            // Toast.fail("Input a 6 digits password.");
            this.$refs.PASSWORD.style.borderColor = "#FF4141";
            this.$refs.PASSWORD.focus();
            return;
          }
          if (this.payload.user.password.confirm.length == 0) {
            this.$showTips({ msg: "Input confirmation password" });
            // Toast.fail("Input confirmation password");
            this.$refs.CONFIRMPASSWORD.style.borderColor = "#FF4141";
            this.$refs.CONFIRMPASSWORD.focus();
            return;
          }
          if (
            this.payload.user.password.confirm.length < 0 &&
            this.payload.user.password.confirm.length < 6
          ) {
            this.$showTips({ msg: "Input a 6 digits confirmation password." });
            // Toast.fail("Input a 6 digits confirmation password.");
            this.$refs.CONFIRMPASSWORD.style.borderColor = "#FF4141";
            this.$refs.CONFIRMPASSWORD.focus();
            return;
          }
          if (
            this.payload.user.password.initial !=
            this.payload.user.password.confirm
          ) {
            this.$showTips({ msg: "The passwords do not match" });
            // Toast.fail("The passwords do not match");
            this.$refs.CONFIRMPASSWORD.style.borderColor = "#FF4141";
            this.$refs.CONFIRMPASSWORD.focus();
            return;
          }

          //#endregion
          let params = {
            email: this.getRegisterEmail,
            password: this.payload.user.password.confirm,
            country: this.payload.user.location.country,
            state: this.payload.user.location.state,
            firstName: this.payload.user.name.first,
            lastName: this.payload.user.name.last,
            age: this.payload.user.age,
            dateOfBirth: this.payload.user.dateOfBirth,
            languages: 0,
          };
          this.showLoading("Registering...");
          this.$api.user
            .addUser(params)
            .then((res) => {
              this.hideLoading();
              if (res.data.code === 200) {
                this._setLoginState(true);
                this.payload = {
                  user: {
                    name: {
                      first: "",
                      last: "",
                    },
                    age: "",
                    showAge: "",
                    dateOfBirth: "",
                    location: {
                      country: "",
                      countryName: "",
                      state: "",
                      stateName: "",
                    },
                    password: {
                      initial: "",
                      confirm: "",
                    },
                  },
                };
                window.localStorage.setItem("front-token", res.data.data);
                this.$api.user
                  .findUser()
                  .then((res) => {
                    if (res.data.code === 200) {
                      this._setLoginInfo(res.data.data);
                      this.$router.replace({ name: "pair_main" });
                    } else {
                      this.$showTips({ msg: res.data.message });
                      // Toast.fail(res.data.message);
                    }
                  })
                  .catch((res) => {
                    this.$showTips({ msg: res.data.message });
                    // Toast.fail(res.data.message);
                  });
              } else {
                this.$showTips({ msg: res.data.message });
                // Toast.fail(res.data.message);
              }
            })
            .catch((res) => {
              this.hideLoading();
              this.$showTips({ msg: res.data.message });
              // Toast.fail(res.data.message);
            });
          break;
        case "terms":
          this.$router.push({
            name: "webview",
            params: {
              url: "https://www.dimeindustries.com/terms-conditions/",
            },
          });
          break;
      }
    },
    // 日期格式化
    formatter(val) {
      switch (val) {
        case 1:
          return "Jan";
        case 2:
          return "Feb";
        case 3:
          return "Mar";
        case 4:
          return "Apr";
        case 5:
          return "May";
        case 6:
          return "Jun";
        case 7:
          return "Jul";
        case 8:
          return "Aug";
        case 9:
          return "Sept";
        case 10:
          return "Oct";
        case 11:
          return "Nov";
        case 12:
          return "Dec";
        default:
          break;
      }
    },
    /**
     * 计算实际年龄，精确到天
     * @param {*} birthday array [year, month, day]
     * @return array
     */
    getAge(birthday) {
      // 新建日期对象
      let date = new Date();
      // 今天日期，数组，同 birthday
      let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
      // 分别计算年月日差值
      let age = today.map((value, index) => {
        return value - birthday[index];
      });
      // 当天数为负数时，月减 1，天数加上月总天数
      if (age[2] < 0) {
        // 简单获取上个月总天数的方法，不会错
        let lastMonth = new Date(today[0], today[1], 0);
        age[1]--;
        age[2] += lastMonth.getDate();
      }
      // 当月数为负数时，年减 1，月数加上 12
      if (age[1] < 0) {
        age[0]--;
        age[1] += 12;
      }
      return age[0];
    },
  },
  computed: {
    ...mapGetters(["getMainColor", "getAccentColor", "getRegisterEmail"]),
    firstNameSet() {
      return this.payload.user.name.first.length > 0 ? true : false;
    },
    lastNameSet() {
      return this.payload.user.name.last.length > 0 ? true : false;
    },
    countrySet() {
      return this.payload.user.location.countryName.length > 0 ? true : false;
    },
    stateSet() {
      return this.payload.user.location.stateName.length > 0 ? true : false;
    },
    nameSet() {
      return this.firstNameSet && this.lastNameSet ? true : false;
    },
    dobSet() {
      return this.payload.user.age ? true : false;
    },
    initialPasswordSet() {
      return this.payload.user.password.initial.length >= 6 &&
        this.payload.user.password.initial.length <= 20
        ? true
        : false;
    },
    confirmPasswordSet() {
      return this.payload.user.password.confirm.length >= 6 &&
        this.payload.user.password.confirm.length <= 20
        ? true
        : false;
    },
    initialAndConfirmPasswordsSet() {
      return this.initialPasswordSet && this.confirmPasswordSet ? true : false;
    },
    passwordsMatch() {
      return this.payload.user.password.initial ===
        this.payload.user.password.confirm
        ? true
        : false;
    },
    passwordSet() {
      return this.initialAndConfirmPasswordsSet && this.passwordsMatch
        ? true
        : false;
    },
    profileComplete() {
      return this.nameSet && this.dobSet && this.passwordSet ? true : false;
    },
    validSubmission() {
      return this.profileComplete ? false : true;
    },
    isDisabledConfirm() {
      if (
        this.payload.user.name.first.length > 0 &&
        this.payload.user.name.last.length > 0 &&
        this.payload.user.age > 0 &&
        this.payload.user.location.countryName.length > 0 &&
        this.payload.user.location.stateName.length > 0 &&
        this.payload.user.password.initial.length > 0 &&
        this.payload.user.password.confirm.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
input {
  text-align: center;
}

::placeholder {
  color: white;
  text-align: center;
}

.button-confirm:disabled {
  background: #959595 !important;
}
</style>